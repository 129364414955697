import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Block } from '../components'
import { container, padding, colours, bgImage, wrapper, type, pagePaddingTop } from '../styles/global'
import { media } from '../styles/utils'

const Resource = (props) => {
	const data = props.previewData || props?.pageContext?.data

	const renderHero = () => {
		return (
			<Hero
				colour={data?.bg_colour}
			>
				<Container>
					<Subheading>
						{data.sub_title}
					</Subheading>

					<Info>
						{data.hero_image && (
							<Img
								src={data.hero_image?.sizes?.medium2}
							/>
						)}

						{(data.title_override || data.title) && (
							<Heading
								as={'h1'}
								dangerouslySetInnerHTML={{__html: data.title_override || data.title}}
							/>
						)}	
					</Info>
				</Container>
			</Hero>
		)
	}

	const renderBlocks = () => {
		if (!data.blocks) return;
	
		return data.blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					{...block}
				/>
			)
		})
	}

	const renderRelated = () => {
		if (!data.related_essays) return;

		const items = data.related_essays.map((item, i) => {  

			item = {
				...item,
				...item.acf
			}

			return (
				<Item
					key={i}
					as={Link}
					to={`/resource/${item.slug}`}
				>
					{item.hero_image && (
						<Image
							title={item?.hero_image?.alt}
							image={item.hero_image?.sizes?.medium2}
						/>
					)}
				</Item>
			)
		})

		return (
			<Related>
				<Heading>Related Resources</Heading>
				<Items>
					{items}
				</Items>
			</Related>
		)
	}
	
	return (
		<Layout
			meta={data.seo}
			headerFloating={true}
			breadcrumb={'Resources'}
			breadcrumbLink={'/resources'}
			breadcrumbDisableH1={true}
        >
			{renderHero()}

			<Blocks>
				<Container>
					{renderBlocks()}
					{renderRelated()}
				</Container>
			</Blocks>
		</Layout>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``

const Img = styled.img``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
    align-items: center;
	height: 100%;
    padding-bottom: 80px;

	${media.phone`
		padding-bottom: 32px;
	`}
`

// Hero

const Hero = styled.div`
	display: flex;
	background-color: ${colours.blue};
	${padding};
	${pagePaddingTop};
	padding-bottom: 70px;

	${media.phone`
		padding-top: 90px;
		padding-bottom: 0;
		height: 100vh;
	`}

	${Container} {
		position: relative;
		padding: 0;
		width: 100%;

		${Subheading} {
			align-self: center;
    		padding-bottom: 55px;

			${media.phone`
				padding-bottom: 20px;
			`}
		}

		${Info} {
			display: flex;
			flex-direction: column;
			height: 100%;		

			${media.phone`
				margin-top: 12px;
			`}

			${Img} {
				height: 61vh;
				max-width: calc(100vw - 80px);
				object-fit: contain;

				${media.phone`
					object-fit: cover;
					width: 100%;
					max-width: calc(100vw - 40px);
					height: 366px;
				`}
			}

			${Heading} {
				margin-top: 42px;
				text-align: center;

				${media.phone`
					margin-top: auto;
					margin-bottom: auto;

					${media.phone`
						font-size: 18px;
						line-height: 1.2em;
					`}
				`}
			}
		}
	}


	${props => {
		if (props.colour) return css`
			background: ${props.colour};
		`
	}}
`

// Blocks

const Blocks = styled.div`
	margin-top: 128px;

	${media.phone`
		margin-top: 50px;
	`}
`

// Related Essays

const Related = styled.div`
	width: 100%;
	max-width: 960px;

	display: flex;
	flex-direction: column;
	align-items: center;

	${Heading} {
		${type.bodySmall};
		text-transform: uppercase;
	}

	${Items} {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 96px;

		${media.phone`
			margin-top: 50px;
			flex-direction: column;
		`}
	
		${Item} {
			flex: 0 1 calc(33.3% - 20px);
			flex-basis: calc(33.3% - 20px);

			${media.phone`
				flex-basis: 100%;

				&:not(:last-child) {
					margin-bottom: 24px;
				}
			`}

			${Image} {
				padding-bottom: 115%;
				width: 100%;
				filter: grayscale(1);
				transition: all 0.25s ease;

				&:hover {
					filter: grayscale(0);
				}
			}
		}
	}
`

export default Resource