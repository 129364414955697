import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

// import DefaultPage from '../templates/default-page'
import Index from './index'
import ClientServices from './client-services'
import ValueOfDesign from './the-value-of-design'
import Contact from './contact-social'
import OfficeExpertise from './office-expertise'
import Resources from './resources'
import Resource from '../templates/resource'

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/more/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;

				if (!data?.acf && data?.acf_json) {
					try {
						const acf = JSON.parse(data.acf_json)

						if (acf) {
							data.acf = acf
						}
					} catch {
						console.log('Error parsing ACF JSON')
					}
				}

                setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			//'page': DefaultPage,         
			'page-home': Index,
			'page-client-services': ClientServices,
			'page-value-of-design': ValueOfDesign,
			'page-contact': Contact,
			'page-office-expertise': OfficeExpertise,
			'page-resources': Resources,
			'resources': Resource
        }
        		
        const Component = templates[postType];

		return (
			<Component
				previewData={{
					...data,
					...data.acf
				}}
				pageContext={{
					...data,
					...data.acf,
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

